import React from "react";
import {Card, CardText} from "reactstrap";
import '../css/Alignment.css';
import '../css/Components.css';
import '../css/Animations.css';
import '../css/Elements.css'
import '../css/Fonts.css'
import '../css/Parallax.css'
import '../css/Size.css'
import {Col, Grid, GymnastProvider} from "gymnast";
import {Parallax} from "react-parallax";
import scales from "../resources/images/blue-scales-fade.png";
import Fade from "react-reveal";
import CustomButton from "../components/OneceHubBookingButton";


const translucent = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    TALL: {...translucent, minHeight: 200, align: "top"},
    MEDIUM: {...translucent, minHeight: 400},
    SMALL: {...translucent, minHeight: 50}
};

class BlogItem2 extends React.Component {
    render() {
        function FullWidthGrid() {

            return (
                <div className="background">
                    <GymnastProvider>
                        <Parallax bgImage={scales} strength={500}>
                            <Col style={style.SMALL}/>

                            <Col style={{minHeight: '20vw', align: "top", backgroundColor: 'white'}}>
                                <Card className='border-0' style={{}}>
                                    <Grid marginTop="S" paddingTop="0">
                                        <Col style={style.SMALL}/>

                                        <Col size={1} style={style.SMALL}/>
                                        <Col size={10} style={style.SMALL}>
                                            <Card className=' width100 border-0 '
                                                  style={{
                                                      backgroundColor: '#FFFFFF',
                                                      marginLeft: '10px',
                                                      marginRight: '10px',
                                                      width: '100%'
                                                  }}>
                                                <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                                          style={{color: "black", textAlign: 'left'}}>
                                                        <div>
                                                            <h2 className='playfair_Display' style={{fontSize: '46px'}}>From Body Coach success to Zoe Science & Nutrition transformation:
                                                                A journey of discovery, adaptation, and renewed body confidence</h2>
                                                            <p className='CormorantGaramondWithoutSize' style={{textAlign: 'start', fontSize: '20px'}}>January 13 2024</p>
                                                        </div>
                                                    <br/>
                                                    <Fade left big >
                                                        <div>
                                                            <p style={{fontSize: '24px'}}>In September 2023, reality hit hard: I'd gained 3 kg, and my waist sneaked back to its 2020 pre-
                                                                <a style={{fontWeight: 'bold', color: 'black'}} target='blank' href={"https://www.thebodycoach.com/"}>Body Coach </a>
                                                                 app dimensions. The frustration and confusion were tangible—I sensed something was off, yet
                                                                the mystery of my changing body baffled me. Refusing to succumb to my pre-Joe weight, I was
                                                                determined to uncover what was unfolding within me. Thus began my four-month journey of (more)
                                                                self-discovery and transformation.</p>
                                                            <br/>
                                                            <p style={{fontSize: '24px'}}>Having enjoyed significant success with
                                                                the <a style={{fontWeight: 'bold', color: 'black'}} target='blank' href={"https://www.lifecoach-directory.org.uk/memberarticles/how-to-be-fit-healthy-and-body-confident-at-50"}>Body Coach</a> app
                                                                since 2021—shedding 14 kg, 2 dress
                                                                sizes, and 35 cm—I found myself at 53, navigating weight creeping up, my waist expanding, and my
                                                                size 12 jeans no longer meeting in the middle. Feeling sluggish, bloated, and energy-deprived, I
                                                                grappled with the mystery of my changing physique. Despite maintaining my workout routine, and
                                                                normal eating the pounds clung stubbornly, and my frustration grew.</p>
                                                            <br/>
                                                            <p style={{fontSize: '24px'}}>With suspicions of hormonal shifts during menopause, I turned
                                                                to <a style={{fontWeight: 'bold', color: 'black'}} target='blank' href={"https://zoe.com/"}>Zoe Science and Nutrition</a>, an
                                                                avenue I'd explored through year-long podcast listening and participation in the BIG IF study focused
                                                                on eating within a window. Though hesitant due to the financial commitment, the changing
                                                                dynamics of my body prompted a re-evaluation.</p>
                                                            <br/>
                                                        </div>
                                                    </Fade>
                                                </CardText>
                                            </Card>
                                        </Col>
                                        <Col size={1} style={style.SMALL}/>
                                        <Col size={1} style={style.SMALL}/>
                                        <Col size={10} style={style.SMALL}>
                                            <Card className=' width100 border-0 '
                                                  style={{
                                                      backgroundColor: '#FFFFFF',
                                                      marginLeft: '10px',
                                                      marginRight: '10px',
                                                      width: '100%'
                                                  }}>
                                                <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                                          style={{color: "black", textAlign: 'left'}}>
                                                    <Fade left big >
                                                        <div>
                                                            <p style={{fontSize: '24px'}}>On October 22, 2023, I undertook the program's test day, initiating the full program on November 11
                                                                after receiving my results. Shock set in as I confronted poor Blood Sugar and Blood Fat levels,
                                                                contrasting with a surprisingly robust microbiome. My overall diet scored poorly, revealing a stark
                                                                truth: what worked in January 2021 no longer served me as my hormones evolved. My body's
                                                                response to blood sugar had shifted, and fat metabolism lagged, requiring over 8 hours to process
                                                                dietary fats.</p>
                                                        </div>
                                                    </Fade>
                                                </CardText>
                                            </Card>
                                        </Col>
                                        <Col size={1} style={style.SMALL}/>
                                        <Col size={1} style={style.SMALL}/>
                                        <Col size={10} style={style.SMALL}>
                                            <Card className=' width100 border-0 '
                                                  style={{
                                                      backgroundColor: '#FFFFFF',
                                                      marginLeft: '10px',
                                                      marginRight: '10px',
                                                      width: '100%'
                                                  }}>
                                                <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                                          style={{color: "black", textAlign: 'left', fontSize: '24px'}}>
                                                    <Fade left big >
                                                        <div>
                                                            <p style={{fontWeight: 'bold'}}>To adapt, I embraced changes:</p>
                                                                <ul>
                                                                    <li>Increased plant consumption—embracing more fruits, vegetables, nuts, and seeds.</li>
                                                                    <li>Incorporated lentils and beans into various meals most days.</li>
                                                                    <li>Temporarily cut out beef, sausages, chorizo, and bacon to assess their impact.</li>
                                                                    <li>Welcomed more nuts, discovering a newfound love for almonds, especially when covered in
                                                                        dark chocolate.</li>
                                                                    <li>Bid farewell to dried fruits to curb sugar spikes.</li>
                                                                    <li>Adjusted breakfasts to pair oats with nuts/seeds, balancing blood sugar.</li>
                                                                    <li>Monitored and reduced daily fat intake, spacing it out strategically.</li>
                                                                    <li>Modified my homemade bread recipes, favouring seeded, wholegrain, and rye variations to
                                                                        avoid blood sugar spikes and bloating.</li>
                                                                </ul>
                                                            <br/>
                                                            <p style={{fontWeight: 'bold'}}>The results have been transformative:</p>
                                                            <ul>
                                                                <li>Shedding 2 kg since November.</li>
                                                                <li>Waist measurement back to normal, comfortably fitting into size 12 jeans.</li>
                                                                <li>Heightened energy levels and increased alertness.</li>
                                                                <li>No longer driven by hunger—indulging in chocolate is a choice, not a necessity.</li>
                                                                <li>Discovered a love for nuts and seeds, with almonds emerging as a favourite.</li>
                                                                <li>Improved sleep quality.</li>
                                                                <li>Freedom from bloating, a visibly flatter stomach.</li>
                                                                <li>Creative exploration of vegetable-based dishes.</li>
                                                                <li>Reduced intake of ultra-processed foods.</li>
                                                                <li>Developed a newfound interest in high-quality Spanish beans.</li>
                                                            </ul>
                                                        </div>
                                                    </Fade>
                                                    <br/>
                                                    <Fade right >
                                                        <div>
                                                            <p style={{fontSize: '28px', fontWeight: 'bold'}}>Similarities between
                                                                the <a style={{fontWeight: 'bold', color: 'black'}} target='blank' href={"https://www.thebodycoach.com/"}>Body Coach</a> and <a style={{fontWeight: 'bold', color: 'black'}} target='blank' href={"https://zoe.com/"}>Zoe Science & Nutrition</a></p>
                                                            <p>I observed big similarities between the two programmes and they've both played a pivotal role in
                                                                shaping my understanding of how food impacts how I work, rest and play. Some key parallels that I
                                                                have noticed:</p>
                                                            <br/>
                                                            <ul>
                                                                <li>Both programs advocate for the art of cooking from scratch, emphasising the importance of
                                                                    steering clear of convenience foods.</li>
                                                                <li>Instead of fixating on calorie counting, both emphasise the significance of maintaining a well-
                                                                    rounded diet comprising protein, fats, and carbs in harmony.</li>
                                                                <li>Both encourage the consumption of healthy fats, such as avocados, Greek yoghurts, cheese,
                                                                    and nuts.</li>
                                                                <li>The shared philosophy of enjoying a diverse range of foods and dismissing the emphasis on
                                                                    the scales is a common thread running through both programs!</li>
                                                            </ul>
                                                            <br/>
                                                            <p>I am still eating a lot of Joe’s food from the app and his books, but I am identifying through the Zoe
                                                                scoring which ones are best for my body.</p>
                                                            <br/>
                                                            <p>While not everyone can afford or desires to invest in Zoe, I chose it for its importance to me at the
                                                                time. This isn't a promotion but a sharing of my journey, recognising the synergy between the Body
                                                                Coach and Zoe nutrition styles. It's an ongoing exploration, but I now feel at ease with my diet,
                                                                feeding my body what it truly needs. The most significant sign? I'm no longer plagued by hunger—my
                                                                body's needs are being met.</p>
                                                            <br/>
                                                            <p>I wonder what would happen if Joe met Zoe? I can only think amazing things!</p>
                                                        </div>
                                                        <br/>
                                                    </Fade>
                                                    <Fade right >
                                                        <div>
                                                            <h2 className='playfair_Display' style={{fontSize: '40px'}}>I hope my journey resonates, inspires, or sparks your interest. However it lands with you, thank you
                                                                for reading. If this has resonated with you, I would love to hear from you -
                                                                <CustomButton borderRadius="5px" text="book a call with me here" color='#FCBFBB'/>
                                                                .</h2>
                                                        </div>
                                                    </Fade>
                                                </CardText>
                                            </Card>
                                        </Col>
                                        <Col size={1} style={style.SMALL}/>
                                    </Grid>
                                </Card>
                            </Col>
                            <Col style={style.SMALL}/>
                        </Parallax>
                    </GymnastProvider>
                </div>


            );
        }

        return (
            <FullWidthGrid/>
        );
    }
}

export default BlogItem2;