import React from "react";
import {Card, CardText} from "reactstrap";
import '../css/Alignment.css';
import '../css/Components.css';
import '../css/Animations.css';
import '../css/Elements.css'
import '../css/Fonts.css'
import '../css/Parallax.css'
import '../css/Size.css'
import {Col, Grid, GymnastProvider} from "gymnast";
import {Parallax} from "react-parallax";
import scales from "../resources/images/blue-scales-fade.png";
import Fade from "react-reveal";
import CustomButton from "../components/OneceHubBookingButton";


const translucent = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    TALL: {...translucent, minHeight: 200, align: "top"},
    MEDIUM: {...translucent, minHeight: 400},
    SMALL: {...translucent, minHeight: 50}
};

class BlogItem3 extends React.Component {
    render() {
        function FullWidthGrid() {

            return (
                <div className="background">
                    <GymnastProvider>
                        <Parallax bgImage={scales} strength={500}>
                            <Col style={style.SMALL}/>

                            <Col style={{minHeight: '20vw', align: "top", backgroundColor: 'white'}}>
                                <Card className='border-0' style={{}}>
                                    <Grid marginTop="S" paddingTop="0">
                                        <Col style={style.SMALL}/>

                                        <Col size={1} style={style.SMALL}/>
                                        <Col size={10} style={style.SMALL}>
                                            <Card className=' width100 border-0 '
                                                  style={{
                                                      backgroundColor: '#FFFFFF',
                                                      marginLeft: '10px',
                                                      marginRight: '10px',
                                                      width: '100%'
                                                  }}>
                                                <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                                          style={{color: "black", textAlign: 'left'}}>
                                                        <div>
                                                            <h2 className='playfair_Display' style={{fontSize: '46px'}}>How I took control of my finances with the Rebel Finance School</h2>
                                                            <p className='CormorantGaramondWithoutSize' style={{textAlign: 'start', fontSize: '20px'}}>November 01 2024</p>
                                                        </div>
                                                    <br/>
                                                    <Fade left big >
                                                        <div>
                                                            <p style={{fontSize: '24px'}}>What if I told you that financial freedom is within your reach, no matter where you’re starting
                                                                from? Since I started my business four years ago, I’ve always had a goal to sort my finances
                                                                – to build up my savings and gain financial freedom. Yet, somehow, it always seemed out of
                                                                reach.</p>
                                                            <br/>
                                                            <p style={{fontSize: '24px', fontWeight: 'bold'}}>But this year, everything changed.</p>
                                                            <br/>
                                                            <p style={{fontSize: '24px'}}>After completing Rebel Finance School 2024, I’ve not only transformed my relationship with
                                                                money, but I now have a clear plan for financial freedom. I know when I can be free to travel
                                                                more and work even more flexibly than I do now.</p>
                                                            <br/>
                                                            <br/>
                                                            <p style={{fontSize: '28px', fontWeight: 'bold'}}>Here’s how it happened and how these lessons might help you too</p>
                                                            <br/>
                                                        </div>
                                                    </Fade>
                                                </CardText>
                                            </Card>
                                        </Col>
                                        <Col size={1} style={style.SMALL}/>
                                        <Col size={1} style={style.SMALL}/>
                                        <Col size={10} style={style.SMALL}>
                                            <Card className=' width100 border-0 '
                                                  style={{
                                                      backgroundColor: '#FFFFFF',
                                                      marginLeft: '10px',
                                                      marginRight: '10px',
                                                      width: '100%'
                                                  }}>
                                                <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                                          style={{color: "black", textAlign: 'left', fontSize: '24px'}}>
                                                    <Fade left big >
                                                        <div>
                                                                <ol>
                                                                    <li> <p style={{fontWeight: 'bold'}}>Profit First: A Game Changer for My Business Finances</p>
                                                                        I read and implemented the Profit First system, and it transformed the way I manage money
                                                                        in my business. On October 1st, I took my first profit, and I already have tax money stored
                                                                        away for the year. This simple shift has brought me more control and clarity in managing my
                                                                        business finances.
                                                                    </li>
                                                                    <br/>
                                                                    <li> <p style={{fontWeight: 'bold'}}>A Smarter Pension: Moving to Vanguard</p>
                                                                        I moved my pension into a better-performing global fund with Vanguard. The low-cost fees
                                                                        and self-management have given me peace of mind about my financial future.
                                                                    </li>
                                                                    <br/>
                                                                    <li> <p style={{fontWeight: 'bold'}}>Understanding My Future: Income Forecasting</p>
                                                                        For the first time, I’ve fully forecasted my income for the rest of my life. Knowing that I can
                                                                        stop working at 61 if I want is an incredible relief. It’s not that I plan to retire that early, but
                                                                        having the option gives me freedom.
                                                                    </li>
                                                                    <br/>
                                                                    <li> <p style={{fontWeight: 'bold'}}>Investing Isn’t Just for the Wealthy</p>
                                                                        One of the biggest surprises? Investing isn’t just for the rich. It’s not about stock picking
                                                                        either! Thanks to what I’ve learned, I’m now self-managing my investments, and it’s much
                                                                        easier than I thought.
                                                                    </li>
                                                                    <br/>
                                                                    <li> <p style={{fontWeight: 'bold'}}>Financial Freedom: Reframing My Values</p>
                                                                        For years, I avoided putting money in my list of values, thinking it made me “greedy.” But
                                                                        through this journey, I’ve reframed it as financial freedom – which is now one of my top five
                                                                        values. It’s about having the independence to live life on my terms, not about wealth for
                                                                        wealth’s sake.
                                                                    </li>
                                                                    <br/>
                                                                    <li> <p style={{fontWeight: 'bold'}}>Money Talks: A New Tradition with My Daughter</p>
                                                                        I did the Rebel Finance School with my daughter, and our Monday night money meetings
                                                                        have transformed our relationship with money. We still diarise monthly finance meetings to
                                                                        track our progress, discuss goals, and review what we can improve. Even when we were on
                                                                        holiday in Paris, we made time for our September finance meeting over morning coffee in a
                                                                        Parisian bar.
                                                                    </li>
                                                                    <br/>
                                                                    <li> <p style={{fontWeight: 'bold'}}>The Final Piece of the Puzzle</p>
                                                                        Completing Rebel Finance School was the final piece in my journey to living the life I want.
                                                                        For that, I’ll always be grateful to Katie and Alan Donegan, who run this amazing free
                                                                        course. I’ve even signed up to be a Rebel Ninja volunteer for next year!
                                                                    </li>
                                                                </ol>
                                                            <br/>
                                                        </div>
                                                    </Fade>
                                                    <br/>
                                                    <Fade right >
                                                        <div>
                                                            <p style={{fontSize: '28px', fontWeight: 'bold'}}>If any of this resonates with you, I encourage you to check out the
                                                                <a style={{fontWeight: 'bold', color: 'black'}} target='blank' href={"https://rebeldonegans.com/finance/rfs/"}> Rebel Finance School.</a>
                                                            </p>
                                                            <br/>
                                                            <p>While this blog is all about my financial journey, my main focus is helping people improve
                                                                their careers and workplace relationships. If you’d like to explore how coaching can help you
                                                                in these areas,
                                                                <CustomButton borderRadius="5px" text="book a free discovery call with me here" color='#FCBFBB'/>
                                                                .
                                                                Let’s work together to clarify your
                                                                goals and create the career you truly want.</p>
                                                            <br/>
                                                        </div>
                                                        <br/>
                                                    </Fade>
                                                </CardText>
                                            </Card>
                                        </Col>
                                        <Col size={1} style={style.SMALL}/>
                                    </Grid>
                                </Card>
                            </Col>
                            <Col style={style.SMALL}/>
                        </Parallax>
                    </GymnastProvider>
                </div>


            );
        }

        return (
            <FullWidthGrid/>
        );
    }
}

export default BlogItem3;